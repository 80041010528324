import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";

import Icon from "components/Icon";
import PageSection from "components/PageSection";
import CallbackForm from "../../Forms/CallbackForm";
import SendEmailForm from "../../Forms/SendEmailForm";
import "./Contact.scss";

export const fragment = graphql`
  fragment ContactMarkdownRemarkFragment on Frontmatter {
    anchor
    header
    subheader
    telephone
    email
  }
`;

const PhoneAndEmail = ({ telephone, email }) => {
  return (
    <Row>
      <Col>
        <a className="d-block link-text" href={`tel:${telephone}`}>
          <Icon iconName="PhoneIcon" size="4x" className="text-primary mb-3" />
          <br />
          {telephone}
        </a>
        <hr className="divider my-4" />
      </Col>
      <Col>
        <a className="d-block link-text" href={`mailto:${email}`}>
          <Icon iconName="EnvelopIcon" size="4x" className="text-primary mb-3" />
          <br />
          {email}
        </a>
        <hr className="divider my-4 d-md-block d-lg-none " />
      </Col>
    </Row>
  );
};

PhoneAndEmail.defaultProps = {
  telephone: null,
  email: null,
};

PhoneAndEmail.propTypes = {
  telephone: PropTypes.string,
  email: PropTypes.string,
};

const Contact = ({ className, frontmatter, callbackNode, contactUsNode }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, telephone, email } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center text-info">
          <h2 className="mt-0">{header}</h2>
          <hr className="divider mt-4 mb-1" />
          <hr className="divider mt-0 mb-4 mx-5" />
          <p className="mb-5 text-info">{subheader}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={5} className="text-center pb-sm-4">
          <CallbackForm frontmatter={callbackNode} />
        </Col>
        <Col lg={2} className="my-auto text-center">
          <PhoneAndEmail telephone={telephone} email={email} />
        </Col>
        <Col lg={5} className="text-center pb-sm-4">
          <SendEmailForm frontmatter={contactUsNode} />
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  callbackNode: PropTypes.object,
  contactUsNode: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
  callbackNode: null,
  contactUsNode: null,
};

export default Contact;
