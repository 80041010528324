import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { Button, Col, Form } from "react-bootstrap";
import axios from "axios";
import clsx from "clsx";
import * as AllIcons from "config/CustomIcons";
import {
  mutateByName,
  mutateValidate,
  mutateValidateByName,
  mutateWithValueAndI18n,
} from "utils/form-utils";
import "./CallbackForm.scss";

export const fragment = graphql`
  fragment CallbackMarkdownRemarkFragment on Frontmatter {
    timePlaceholder
    timeErrorMessage
    time {
      interval0
      interval1
      interval2
      interval3
      interval4
      interval5
      interval6
      interval7
      interval8
    }
    namePlaceholder
    nameErrorMessage
    messagePlaceholder
    messageErrorMessage
    phonePlaceholder
    phoneErrorMessage
    submitText
    title
    checkPolicy
    linkPolicy
  }
`;

// noinspection SpellCheckingInspection
const DEFAULTS = [
  {
    type: "time",
    name: "Time",
    placeholder: "Time interval",
    error: false,
    errorMessage: "Time is not specified!",
    pattern: "[0-9]",
  },
  {
    type: "name",
    name: "Name",
    placeholder: "Name",
    error: false,
    errorMessage: "Name is not specified!",
    pattern: ".+",
  },
  {
    type: "message",
    name: "Message",
    placeholder: "Message",
    error: false,
    errorMessage: "Message is not specified!",
    pattern: ".+",
  },
  {
    type: "phone",
    name: "Phone",
    placeholder: "076xxxxxxx",
    error: false,
    errorMessage: "Phone is not specified correctly!",
    pattern: "0?[0-9]{9}",
  },
];

const CallbackForm = ({ frontmatter, isError, isSuccess, time, name, phone, message }) => {
  const [inputTime, inputName, inputMessage, inputPhone] = DEFAULTS;
  const inputs = [
    mutateWithValueAndI18n(inputTime, time, "time", frontmatter),
    mutateWithValueAndI18n(inputName, name, "name", frontmatter),
    mutateWithValueAndI18n(inputMessage, message, "message", frontmatter),
    mutateWithValueAndI18n(inputPhone, phone, "phone", frontmatter),
  ].map((input) => (input.value ? mutateValidate(input) : input));
  const { submitText, title, checkPolicy, linkPolicy } = frontmatter;
  const {
    time: {
      interval0,
      interval1,
      interval2,
      interval3,
      interval4,
      interval5,
      interval6,
      interval7,
      interval8,
    },
  } = frontmatter;

  const [inputsState, setInputsState] = useState(inputs);
  const [hasError, setError] = useState(isError);
  const [hasSuccess, setSuccess] = useState(isSuccess);

  /* Reset the form state to initial if it has all fields empty  */
  useEffect(() => {
    const timer = setTimeout(() => {
      const isEmpty = inputsState.every((input) => (input.value || "").length === 0);
      if (isEmpty) {
        setInputsState(
          inputsState.map((input) => ({
            ...input,
            error: false,
          })),
        );
      }
      // reset states
      setError(isError);
      setSuccess(isSuccess);
    }, 3500);

    return () => clearTimeout(timer);
  }, [inputsState, hasSuccess, hasError, isError, isSuccess]);

  /* on any change re-validate the value's. */
  const handleOnChange = (event, input) => {
    event.preventDefault();

    const updated = inputsState
      .map((item) => mutateByName(item, input.name, event))
      .map((item) => mutateValidateByName(item, input.name));

    setInputsState(updated);
  };

  /* empty form field on success  */
  const handleOnSuccess = () => {
    setInputsState(inputsState.map((input) => ({ ...input, value: "", error: false })));

    setSuccess(true);
  };

  /* Display error to User. */
  const handleOnFailure = (error) => {
    // eslint-disable-next-line no-console
    console.dir(error?.response?.data);

    setError(true);
  };

  /* POST form data to Netlify */
  const handleOnSubmit = (event) => {
    event.preventDefault();

    if (!event.target.checkValidity()) {
      setInputsState(inputsState.map((input) => mutateValidate(input)));
    } else {
      // make submit to Netlify API
      const data = inputsState.reduce((acc, input) => ({ ...acc, [input.type]: input.value }), {
        lang: "en" /* TODO: inject correct language! */,
        email: "info@norlandservice.se",
      });

      // customize message and remove unneeded fields
      const selectTime = event.target[0];
      const humanTime = selectTime.options[selectTime.selectedIndex].text;
      data.message = `${data.message}. 
        ---
        Please call me back at: ${humanTime}
        ---`;
      delete data.time;

      /* Defined in .env.production OR .env.development. */
      const api = `${process.env.GATSBY_SEND_EMAIL_API_URL}`;

      axios
        .post(api, data)
        .then(() => handleOnSuccess())
        .catch((err) => handleOnFailure(err));
    }
  };

  const [basicTime, basicName, basicMessage, basicPhone] = inputsState;

  return (
    <Form
      className="form-callback-us"
      name="callback"
      noValidate
      onSubmit={handleOnSubmit}
      method="POST"
    >
      <h6 className="text-left text-info">{title}</h6>
      <Form.Row>
        <Col xs={12} sm={6}>
          <Form.Group controlId="formBasicTime">
            <Form.Control
              as="select"
              className="custom-time-selector"
              type={basicTime.type}
              name={basicTime.name}
              required={true}
              placeholder={basicTime.placeholder}
              isInvalid={basicTime.error}
              pattern={basicTime.pattern}
              value={basicTime.value}
              onChange={(event) => handleOnChange(event, basicTime)}
            >
              <option value="0">{interval0}</option>
              <option value="1">{interval1}</option>
              <option value="2">{interval2}</option>
              <option value="3">{interval3}</option>
              <option value="4">{interval4}</option>
              <option value="5">{interval5}</option>
              <option value="6">{interval6}</option>
              <option value="7">{interval7}</option>
              <option value="8">{interval8}</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid" className="text-left text-danger-light">
              {basicTime.errorMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formBasicPhone">
            <Form.Control
              type={basicPhone.type}
              name={basicPhone.name}
              required={true}
              placeholder={basicPhone.placeholder}
              isInvalid={basicPhone.error}
              pattern={basicPhone.pattern}
              value={basicPhone.value}
              onChange={(event) => handleOnChange(event, basicPhone)}
            />
            <Form.Control.Feedback type="invalid" className="text-left text-danger-light">
              {basicPhone.errorMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Group controlId="formBasicName">
        <Form.Control
          type={basicName.type}
          name={basicName.name}
          required={true}
          placeholder={basicName.placeholder}
          isInvalid={basicName.error}
          pattern={basicName.pattern}
          value={basicName.value}
          onChange={(event) => handleOnChange(event, basicName)}
        />
        <Form.Control.Feedback type="invalid" className="text-left text-danger-light">
          {basicName.errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBasicMessage">
        <Form.Control
          as="textarea"
          rows={2}
          type={basicMessage.type}
          name={basicMessage.name}
          required={true}
          isInvalid={basicMessage.error}
          placeholder={basicMessage.placeholder}
          pattern={basicMessage.pattern}
          value={basicMessage.value}
          onChange={(event) => handleOnChange(event, basicMessage)}
        />
        <Form.Control.Feedback type="invalid" className="text-left text-danger-light">
          {basicMessage.errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
      <div className="d-flex justify-content-between">
        <Form.Group id="formGridCheckbox" className="my-auto">
          <Form.Check type="checkbox" required={true} className="form-check-inline" />
          <Form.Label className="text-info">
            {checkPolicy} <Link to="/privacy">{linkPolicy}</Link>
          </Form.Label>
        </Form.Group>

        <Button variant="secondary" type="submit" className="text-nowrap">
          {submitText}
        </Button>
      </div>
      <div
        className={clsx("form-state-wrapper", {
          "d-block": hasSuccess || hasError,
          "d-none": !hasError && !hasSuccess,
        })}
      >
        <div
          className={clsx("form-state", {
            "d-block": hasError,
            "d-none": !hasError,
          })}
        >
          <AllIcons.BanIcon className="form-errorIcon" />
        </div>
        <div
          className={clsx("form-state", {
            "d-block": hasSuccess,
            "d-none": !hasSuccess,
          })}
        >
          <AllIcons.CircleCheckIcon className="form-successIcon" />
        </div>
      </div>
    </Form>
  );
};

CallbackForm.defaultProps = {
  time: 0,
  name: "",
  phone: "",
  message: "",
  isError: false,
  isSuccess: false,
  frontmatter: null,
};

CallbackForm.propTypes = {
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  time: PropTypes.number,
  name: PropTypes.string,
  phone: PropTypes.string,
  message: PropTypes.string,
  frontmatter: PropTypes.shape({
    title: PropTypes.string,
    submitText: PropTypes.string,
    checkPolicy: PropTypes.string,
    linkPolicy: PropTypes.string,
    time: PropTypes.shape({
      interval0: PropTypes.string,
      interval1: PropTypes.string,
      interval2: PropTypes.string,
      interval3: PropTypes.string,
      interval4: PropTypes.string,
      interval5: PropTypes.string,
      interval6: PropTypes.string,
      interval7: PropTypes.string,
      interval8: PropTypes.string,
    }),
    timePlaceholder: PropTypes.string,
    timeErrorMessage: PropTypes.string,
    namePlaceholder: PropTypes.string,
    nameErrorMessage: PropTypes.string,
    messagePlaceholder: PropTypes.string,
    messageErrorMessage: PropTypes.string,
    phonePlaceholder: PropTypes.string,
    phoneErrorMessage: PropTypes.string,
  }),
};

export default CallbackForm;
