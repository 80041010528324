/* eslint-disable react/no-array-index-key */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";
import "./PaintingDetails.scss";
import { graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import RenderParagraph from "components/RenderParagraph";

export const fragment = graphql`
  fragment PaintingDetailsMarkdownRemarkFragment on Frontmatter {
    anchor
    title
    subheader
    paragraphs {
      ...ParagraphFragment
    }
  }
`;

const NUMBER_PARAGRAPHS_IN_COLUMN = 7;

const PaintingDetails = ({ className, frontmatter }) => {
  if (!frontmatter) return null;

  const { anchor, title, subheader, paragraphs } = frontmatter;
  return (
    <PageSection className={clsx("py-5", "painting-section", className)} id={anchor}>
      <Row>
        <Col className="painting-texts text-sm-center text-md-left">
          <h1>{title}</h1>
          <h2 className="h4">{subheader}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} className="painting-texts text-left">
          {paragraphs &&
            paragraphs
              .slice(0, NUMBER_PARAGRAPHS_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
        <Col md={6} sm={12} className="painting-texts text-left">
          {paragraphs &&
            paragraphs
              .slice(NUMBER_PARAGRAPHS_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
      </Row>
    </PageSection>
  );
};

PaintingDetails.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

PaintingDetails.defaultProps = {
  className: null,
  frontmatter: null,
};

export default PaintingDetails;
