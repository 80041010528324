import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import { Col, Row } from "react-bootstrap";

import TimelineItem from "components/TimelineItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import nl2br from "utils/nl2br";

import "./TimeLine.scss";

export const fragment = graphql`
  fragment TimeLineMarkdownRemarkFragment on Frontmatter {
    anchor
    header
    subheader
    imageFileName
    timeline {
      imageFileName
      header
      subheader
      content
    }
  }
`;

export const LAST_TIMELINE_ITEM_INDEX = 6;

const TimeLine = ({ className, frontmatter }) => {
  if (!frontmatter) return null;

  const { anchor, header: rootHeader, subheader: rootSubHeader, timeline} = frontmatter;

  return (
    <PageSection className={clsx(className)} id={anchor}>
      <Row>
        {/* h2, h3 */}
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <Col lg={12}>
          <ul className="timeline">
            {timeline &&
              timeline.map(({ content, header, imageContent, imageFileName, subheader }, ind) => (
                <TimelineItem
                  key={uuidv4()}
                  invert={ind % 2 === 1}
                  noOverlay={ind === LAST_TIMELINE_ITEM_INDEX}
                  imageFileName={imageFileName}
                  header={header}
                  subheader={subheader}
                  content={content}
                  imageContent={
                    imageContent ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: `<h4>${nl2br(imageContent)}</h4>` }}
                      />
                    ) : null
                  }
                />
              ))}
          </ul>
        </Col>
      </Row>
    </PageSection>
  );
};

TimeLine.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.shape({
    anchor: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
    timeline: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        header: PropTypes.string,
        imageContent: PropTypes.string,
        imageFileName: PropTypes.string,
        subheader: PropTypes.string,
      }),
    ),
  }),
};

TimeLine.defaultProps = {
  className: null,
  frontmatter: null,
};

export default TimeLine;
