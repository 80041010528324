import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { graphql } from "gatsby";
import { Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import PageSection from "components/PageSection";
import RenderParagraph from "components/RenderParagraph";
import Image from "components/Image";

import "./AboutLandings.scss";

export const fragment = graphql`
  fragment AboutMarkdownRemarkFragment on Frontmatter {
    anchor
    imageFileName
    header
    subheader
    paragraphs {
      h1
      h2
      text
    }
  }
`;

const AboutLandings = ({ className, frontmatter }) => {
  if (!frontmatter) return null;

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    paragraphs,
    imageFileName,
    imageAlt,
  } = frontmatter;

  return (
    <PageSection className={clsx("py-5", className)} id={anchor}>
      <Row>
        <Col className="aboutL-texts text-sm-center text-md-left">
          <h1>{rootHeader}</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12} className="aboutL-texts text-sm-center text-md-left">
          <div>
            {/* className="d-flex align-items-center" */}
            <Image
              className="img-fluid rounded aboutL-img"
              fileName={imageFileName}
              alt={imageAlt || rootHeader || rootSubHeader}
            />
          </div>
        </Col>
        <Col lg={6} md={12} sm={12} className="aboutL-texts text-left px-5 px-sm-3">
          <div>
            <p>{rootSubHeader}</p>
          </div>
          {paragraphs.map((p) => (
            <RenderParagraph p={p} key={uuidv4()} />
          ))}
        </Col>
      </Row>
    </PageSection>
  );
};

AboutLandings.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.shape({
    anchor: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
    imageFileName: PropTypes.string,
    imageAlt: PropTypes.string,
    paragraphs: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        h1: PropTypes.string,
        h2: PropTypes.string,
      }),
    ),
  }),
};

AboutLandings.defaultProps = {
  className: null,
  frontmatter: null,
};

export default AboutLandings;
