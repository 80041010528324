/* eslint-disable react/no-array-index-key */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Row, Col } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import PageSection from "components/PageSection";
import RenderParagraph from "components/RenderParagraph";
import "./BundleDetails.scss";
import { graphql } from "gatsby";

export const fragment = graphql`
  fragment BundleDetailsMarkdownRemarkFragment on Frontmatter {
    anchor
    title
    subheader
    paragraphs {
      ...ParagraphFragment
    }
  }
`;
export const PARAGRAPHS_IN_COLUMN = 28;

const BundleDetails = ({ className, frontmatter }) => {
  if (!frontmatter) return null;

  const { anchor, title, subheader, paragraphs } = frontmatter;

  return (
    <PageSection className={clsx("py-5", "bundle-section", className)} id={anchor}>
      <Row>
        <Col className="bundle-texts text-sm-center text-md-left">
          <h1>{title}</h1>
          <h2>{subheader}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} className="bundle-texts text-left column-left">
          {paragraphs &&
            paragraphs
              .slice(0, PARAGRAPHS_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
        <Col md={6} sm={12} className="bundle-texts text-left column-right">
          {paragraphs &&
            paragraphs
              .slice(PARAGRAPHS_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
      </Row>
    </PageSection>
  );
};

BundleDetails.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.shape({
    anchor: PropTypes.string,
    title: PropTypes.string,
    subheader: PropTypes.string,
    paragraphs: PropTypes.arrayOf(
      PropTypes.shape({
        h1: PropTypes.string,
        h2: PropTypes.string,
        h3: PropTypes.string,
        h4: PropTypes.string,
        h5: PropTypes.string,
        h6: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }),
};

BundleDetails.defaultProps = {
  className: null,
  frontmatter: null,
};

export default BundleDetails;
