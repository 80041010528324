/* eslint-disable react/no-array-index-key */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";
import "./RotDetails.scss";
import RenderParagraph from "components/RenderParagraph";
import { graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";

export const fragment = graphql`
  fragment RotDetailsMarkdownRemarkFragment on Frontmatter {
    anchor
    title
    subheader
    paragraphs {
      ...ParagraphFragment
    }
  }
`;

export const PARAGRAPH_IN_COLUMN = 6;

const RotDetails = ({ className, frontmatter }) => {
  if (!frontmatter) return null;

  const { anchor, title, subheader, paragraphs } = frontmatter;

  return (
    <PageSection className={clsx("py-5", className)} id={anchor}>
      <Row>
        <Col className="rot-texts text-sm-center text-md-left">
          <h1>{title}</h1>
          <h2>{subheader}</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={8} md={6} sm={12} className="rot-texts text-left column-left">
          {paragraphs &&
            paragraphs
              .slice(0, PARAGRAPH_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
        <Col className="rot-texts text-left p-5 mx-2 rot-example column-right">
          {paragraphs &&
            paragraphs
              .slice(PARAGRAPH_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
      </Row>
    </PageSection>
  );
};

RotDetails.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.shape({
    anchor: PropTypes.string,
    title: PropTypes.string,
    subheader: PropTypes.string,
    paragraphs: PropTypes.arrayOf(
      PropTypes.shape({
        h1: PropTypes.string,
        h2: PropTypes.string,
        h3: PropTypes.string,
        h4: PropTypes.string,
        h5: PropTypes.string,
        h6: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }),
};

RotDetails.defaultProps = {
  className: null,
  frontmatter: null,
};

export default RotDetails;
