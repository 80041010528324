import React from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import { Row, Col } from "react-bootstrap";

import ServiceItem from "components/ServiceItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Services.scss";

export const fragment = graphql`
  fragment ServicesMarkdownRemarkFragment on Frontmatter {
    anchor
    header
    subheader
    content
    services {
      iconName
      header
      content
      link
    }
  }
`;

const Services = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, services } = frontmatter;

  const handleOnClick = (link) => (event) => {
    event.preventDefault();

    navigate(link);
  };

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="text-center">
        {services &&
          services.length > 0 &&
          services.map((service) => (
            <Col md={4} key={service.header} onClick={handleOnClick(service.link)}>
              <ServiceItem {...service} className="cursor-pointer" />
            </Col>
          ))}
      </Row>
    </PageSection>
  );
};

Services.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Services.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Services;
